import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _779dfa77 = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _03b4228c = () => interopDefault(import('../src/pages/delivery-enquete/index.vue' /* webpackChunkName: "pages/delivery-enquete/index" */))
const _3b95bacf = () => interopDefault(import('../src/pages/inflow/index.vue' /* webpackChunkName: "pages/inflow/index" */))
const _7ca74bdb = () => interopDefault(import('../src/pages/integrate-user-error.vue' /* webpackChunkName: "pages/integrate-user-error" */))
const _59c195b8 = () => interopDefault(import('../src/pages/line-account-renewal.vue' /* webpackChunkName: "pages/line-account-renewal" */))
const _2f1cb3ee = () => interopDefault(import('../src/pages/location.vue' /* webpackChunkName: "pages/location" */))
const _7bb19f37 = () => interopDefault(import('../src/pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _6b082764 = () => interopDefault(import('../src/pages/not-authorized.vue' /* webpackChunkName: "pages/not-authorized" */))
const _1f98848c = () => interopDefault(import('../src/pages/not-pit-reservations.vue' /* webpackChunkName: "pages/not-pit-reservations" */))
const _73c3f7ca = () => interopDefault(import('../src/pages/out-of-area.vue' /* webpackChunkName: "pages/out-of-area" */))
const _18839eb4 = () => interopDefault(import('../src/pages/pit-reservation/index.vue' /* webpackChunkName: "pages/pit-reservation/index" */))
const _045081e3 = () => interopDefault(import('../src/pages/quotations/index.vue' /* webpackChunkName: "pages/quotations/index" */))
const _8f01c85c = () => interopDefault(import('../src/pages/receipt/index.vue' /* webpackChunkName: "pages/receipt/index" */))
const _071c0fc4 = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _3e6082dc = () => interopDefault(import('../src/pages/tire-guarantee.vue' /* webpackChunkName: "pages/tire-guarantee" */))
const _4614312e = () => interopDefault(import('../src/pages/tire-repair.vue' /* webpackChunkName: "pages/tire-repair" */))
const _2c10101f = () => interopDefault(import('../src/pages/under-construction.vue' /* webpackChunkName: "pages/under-construction" */))
const _483f90ba = () => interopDefault(import('../src/pages/unfollow-account.vue' /* webpackChunkName: "pages/unfollow-account" */))
const _5371d822 = () => interopDefault(import('../src/pages/delivery-enquete/complete.vue' /* webpackChunkName: "pages/delivery-enquete/complete" */))
const _4a5c55d6 = () => interopDefault(import('../src/pages/delivery-enquete/google-review-measurement.vue' /* webpackChunkName: "pages/delivery-enquete/google-review-measurement" */))
const _71bfec86 = () => interopDefault(import('../src/pages/delivery-enquete/google-reviews.vue' /* webpackChunkName: "pages/delivery-enquete/google-reviews" */))
const _80637d96 = () => interopDefault(import('../src/pages/mypage/edit.vue' /* webpackChunkName: "pages/mypage/edit" */))
const _2df5568c = () => interopDefault(import('../src/pages/mypage/purchases/index.vue' /* webpackChunkName: "pages/mypage/purchases/index" */))
const _11525fd5 = () => interopDefault(import('../src/pages/mypage/quotations/index.vue' /* webpackChunkName: "pages/mypage/quotations/index" */))
const _1fe14af5 = () => interopDefault(import('../src/pages/mypage/vehicles/index.vue' /* webpackChunkName: "pages/mypage/vehicles/index" */))
const _d8ef7272 = () => interopDefault(import('../src/pages/pit-reservation/complete.vue' /* webpackChunkName: "pages/pit-reservation/complete" */))
const _097ecb22 = () => interopDefault(import('../src/pages/pit-reservation/confirm.vue' /* webpackChunkName: "pages/pit-reservation/confirm" */))
const _462e7f31 = () => interopDefault(import('../src/pages/pit-reservation/register.vue' /* webpackChunkName: "pages/pit-reservation/register" */))
const _15edb640 = () => interopDefault(import('../src/pages/pit-reservation/situation.vue' /* webpackChunkName: "pages/pit-reservation/situation" */))
const _a502617a = () => interopDefault(import('../src/pages/pit-reservation/store.vue' /* webpackChunkName: "pages/pit-reservation/store" */))
const _9c7348c2 = () => interopDefault(import('../src/pages/pit-reservation/work.vue' /* webpackChunkName: "pages/pit-reservation/work" */))
const _28fc3d90 = () => interopDefault(import('../src/pages/quotations/complete.vue' /* webpackChunkName: "pages/quotations/complete" */))
const _b01bcdda = () => interopDefault(import('../src/pages/quotations/details.vue' /* webpackChunkName: "pages/quotations/details" */))
const _32e5662e = () => interopDefault(import('../src/pages/receipt/complete.vue' /* webpackChunkName: "pages/receipt/complete" */))
const _cee10e80 = () => interopDefault(import('../src/pages/receipt/confirm.vue' /* webpackChunkName: "pages/receipt/confirm" */))
const _5f23392e = () => interopDefault(import('../src/pages/receipt/enquete.vue' /* webpackChunkName: "pages/receipt/enquete" */))
const _5b992d94 = () => interopDefault(import('../src/pages/receipt/qr/index.vue' /* webpackChunkName: "pages/receipt/qr/index" */))
const _cd98f55a = () => interopDefault(import('../src/pages/receipt/register.vue' /* webpackChunkName: "pages/receipt/register" */))
const _e4ce11ca = () => interopDefault(import('../src/pages/vehicles/registration/index.vue' /* webpackChunkName: "pages/vehicles/registration/index" */))
const _4c0e12b9 = () => interopDefault(import('../src/pages/mypage/pit-reservation/complete.vue' /* webpackChunkName: "pages/mypage/pit-reservation/complete" */))
const _1ff6b8f0 = () => interopDefault(import('../src/pages/mypage/pit-reservation/confirm.vue' /* webpackChunkName: "pages/mypage/pit-reservation/confirm" */))
const _4d3cd72e = () => interopDefault(import('../src/pages/mypage/pit-reservation/situation.vue' /* webpackChunkName: "pages/mypage/pit-reservation/situation" */))
const _0a8a38c4 = () => interopDefault(import('../src/pages/receipt/qr/read.vue' /* webpackChunkName: "pages/receipt/qr/read" */))
const _9afa7e00 = () => interopDefault(import('../src/pages/vehicles/registration/complete.vue' /* webpackChunkName: "pages/vehicles/registration/complete" */))
const _e2c0b46e = () => interopDefault(import('../src/pages/vehicles/registration/confirm.vue' /* webpackChunkName: "pages/vehicles/registration/confirm" */))
const _299f9928 = () => interopDefault(import('../src/pages/vehicles/registration/qr.vue' /* webpackChunkName: "pages/vehicles/registration/qr" */))
const _f4cdeb18 = () => interopDefault(import('../src/pages/vehicles/registration/select-type.vue' /* webpackChunkName: "pages/vehicles/registration/select-type" */))
const _527439e9 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5b567a2c = () => interopDefault(import('../src/pages/mypage/pit-reservation/_id.vue' /* webpackChunkName: "pages/mypage/pit-reservation/_id" */))
const _41753da2 = () => interopDefault(import('../src/pages/mypage/purchases/_id.vue' /* webpackChunkName: "pages/mypage/purchases/_id" */))
const _2c30537d = () => interopDefault(import('../src/pages/mypage/quotations/_id.vue' /* webpackChunkName: "pages/mypage/quotations/_id" */))
const _61bf869d = () => interopDefault(import('../src/pages/mypage/vehicles/_id.vue' /* webpackChunkName: "pages/mypage/vehicles/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact/",
    component: _779dfa77,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/delivery-enquete/",
    component: _03b4228c,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete"
  }, {
    path: "/inflow/",
    component: _3b95bacf,
    pathToRegexpOptions: {"strict":true},
    name: "inflow"
  }, {
    path: "/integrate-user-error/",
    component: _7ca74bdb,
    pathToRegexpOptions: {"strict":true},
    name: "integrate-user-error"
  }, {
    path: "/line-account-renewal/",
    component: _59c195b8,
    pathToRegexpOptions: {"strict":true},
    name: "line-account-renewal"
  }, {
    path: "/location/",
    component: _2f1cb3ee,
    pathToRegexpOptions: {"strict":true},
    name: "location"
  }, {
    path: "/mypage/",
    component: _7bb19f37,
    pathToRegexpOptions: {"strict":true},
    name: "mypage"
  }, {
    path: "/not-authorized/",
    component: _6b082764,
    pathToRegexpOptions: {"strict":true},
    name: "not-authorized"
  }, {
    path: "/not-pit-reservations/",
    component: _1f98848c,
    pathToRegexpOptions: {"strict":true},
    name: "not-pit-reservations"
  }, {
    path: "/out-of-area/",
    component: _73c3f7ca,
    pathToRegexpOptions: {"strict":true},
    name: "out-of-area"
  }, {
    path: "/pit-reservation/",
    component: _18839eb4,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation"
  }, {
    path: "/quotations/",
    component: _045081e3,
    pathToRegexpOptions: {"strict":true},
    name: "quotations"
  }, {
    path: "/receipt/",
    component: _8f01c85c,
    pathToRegexpOptions: {"strict":true},
    name: "receipt"
  }, {
    path: "/terms/",
    component: _071c0fc4,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/tire-guarantee/",
    component: _3e6082dc,
    pathToRegexpOptions: {"strict":true},
    name: "tire-guarantee"
  }, {
    path: "/tire-repair/",
    component: _4614312e,
    pathToRegexpOptions: {"strict":true},
    name: "tire-repair"
  }, {
    path: "/under-construction/",
    component: _2c10101f,
    pathToRegexpOptions: {"strict":true},
    name: "under-construction"
  }, {
    path: "/unfollow-account/",
    component: _483f90ba,
    pathToRegexpOptions: {"strict":true},
    name: "unfollow-account"
  }, {
    path: "/delivery-enquete/complete/",
    component: _5371d822,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-complete"
  }, {
    path: "/delivery-enquete/google-review-measurement/",
    component: _4a5c55d6,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-google-review-measurement"
  }, {
    path: "/delivery-enquete/google-reviews/",
    component: _71bfec86,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-google-reviews"
  }, {
    path: "/mypage/edit/",
    component: _80637d96,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-edit"
  }, {
    path: "/mypage/purchases/",
    component: _2df5568c,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases"
  }, {
    path: "/mypage/quotations/",
    component: _11525fd5,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations"
  }, {
    path: "/mypage/vehicles/",
    component: _1fe14af5,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles"
  }, {
    path: "/pit-reservation/complete/",
    component: _d8ef7272,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-complete"
  }, {
    path: "/pit-reservation/confirm/",
    component: _097ecb22,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-confirm"
  }, {
    path: "/pit-reservation/register/",
    component: _462e7f31,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-register"
  }, {
    path: "/pit-reservation/situation/",
    component: _15edb640,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-situation"
  }, {
    path: "/pit-reservation/store/",
    component: _a502617a,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-store"
  }, {
    path: "/pit-reservation/work/",
    component: _9c7348c2,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-work"
  }, {
    path: "/quotations/complete/",
    component: _28fc3d90,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-complete"
  }, {
    path: "/quotations/details/",
    component: _b01bcdda,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-details"
  }, {
    path: "/receipt/complete/",
    component: _32e5662e,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-complete"
  }, {
    path: "/receipt/confirm/",
    component: _cee10e80,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-confirm"
  }, {
    path: "/receipt/enquete/",
    component: _5f23392e,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-enquete"
  }, {
    path: "/receipt/qr/",
    component: _5b992d94,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr"
  }, {
    path: "/receipt/register/",
    component: _cd98f55a,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-register"
  }, {
    path: "/vehicles/registration/",
    component: _e4ce11ca,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration"
  }, {
    path: "/mypage/pit-reservation/complete/",
    component: _4c0e12b9,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-complete"
  }, {
    path: "/mypage/pit-reservation/confirm/",
    component: _1ff6b8f0,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-confirm"
  }, {
    path: "/mypage/pit-reservation/situation/",
    component: _4d3cd72e,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-situation"
  }, {
    path: "/receipt/qr/read/",
    component: _0a8a38c4,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr-read"
  }, {
    path: "/vehicles/registration/complete/",
    component: _9afa7e00,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-complete"
  }, {
    path: "/vehicles/registration/confirm/",
    component: _e2c0b46e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-confirm"
  }, {
    path: "/vehicles/registration/qr/",
    component: _299f9928,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-qr"
  }, {
    path: "/vehicles/registration/select-type/",
    component: _f4cdeb18,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-select-type"
  }, {
    path: "/",
    component: _527439e9,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/mypage/pit-reservation/:id?/",
    component: _5b567a2c,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-id"
  }, {
    path: "/mypage/purchases/:id/",
    component: _41753da2,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases-id"
  }, {
    path: "/mypage/quotations/:id/",
    component: _2c30537d,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations-id"
  }, {
    path: "/mypage/vehicles/:id/",
    component: _61bf869d,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
